<template>
  <div>
    <patientSelect @onConfirm="patientConfirm" />
    <load-more :data-source="dataList" :total="total" :loading="loading" @load-more="onLoad">
      <prescription-item :data="dataList" type="order" :is-prescription="false" />
    </load-more>
  </div>
</template>
<script>
 import { userModel } from '@/api/user'
 import loadMore from '@/assets/js/mixin/load-more'
export default {
  name:'List',
  mixins: [loadMore],
  data(){
    return{
      Picker:false,
      patientIdCard:'',
      dataList:[]
    }
  },
  methods:{
    getList(){
      let params={
        "zjhm":this.patientIdCard,
        page: this.page,
        pageSize: this.pageSize,
        prescriptionId:''
      }
      userModel.prescriptionList(params).then(res=>{
          this.dataList.push(res.data.records)
          this.total = res.data.total
          this.loading = false
      })
    },
    patientConfirm(value){
      this.patientIdCard=value.zjh||""
      this.page=1
      this.dataList=[]
      this.getList()
    },
    onLoad(){
      this.loading=true
      this.page+=1
      this.getList()
    }
  }
}
</script>
<style scoped>

</style>
